import React, { useEffect } from "react"
import { useRecoilState } from "recoil"
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from "react-router-dom"

import { ErrorBoundary } from "react-error-boundary"
import { LogError } from "./Logging"
import { hasApplicationError } from "state"
import ScrollToTop from "hooks/scrollToTop"

import { PartnerLander } from "pages/PartnerLander"
import { MagicTokenLogin } from "pages/MagicTokenLogin/MagicTokenLogin"
import Dashboard from "pages/Dashboard/Dashboard"
import NewApplication from "pages/NewApplication/NewApplication"
import Application from "pages/Application/Application"
import { RequestMagicLink } from "pages/Login/RequestMagicLink/RequestMagicLink"
import { ResetPassword } from "pages/Login/ResetPassword/ResetPassword"
import { NewPassword } from "pages/Login/ResetPassword/NewPassword"
import { CookieBanner } from "components/molecules/CookieBanner"
import { GenericError } from "pages/Errors/GenericError"
import { ShortCodeRedirect } from "pages/ShortCode/ShortCodeRedirect"

import { CallRecommendations } from "pages/CallRecommendations/CallRecommendations"
import { NurturePropertyDetails } from "pages/Application/Eligibility/Approved/NurturePropertyDetails"
import { RecommendationPreview } from "pages/RecommendationPreview/RecommendationPreview"
import { RateCheckRequest } from "pages/RateCheckRequest"
import { SentryErrorer } from "components/atoms/SentryErrorer"
import { LoginFromSession } from "pages/LoginFromSession/LoginFromSession"
import { ProtectionReviewLandingPage } from "pages/ProtectionReviewLandingPage"
import { RateCheckPromise } from "pages/RateCheckPromise/RateCheckPromise"

export default function () {
  const [applicationError] = useRecoilState(hasApplicationError)

  if (applicationError) {
    return <ErrorFallback />
  }

  window.Intercom && window.Intercom("update", { hide_default_launcher: true })

  return (
    <ErrorBoundary onError={LogError} FallbackComponent={ErrorFallback}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/application/:friendlyId/fact-find/">
            <Redirect to="/dashboard" />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/new-application">
            <NewApplication />
          </Route>
          <Route exact path="/login">
            <RequestMagicLink />
          </Route>
          <Route exact path="/get-started">
            <PartnerLander />
          </Route>
          <Route exact path="/routing-summary">
            <SentryErrorer path="/routing-summary" redirect="/dashboard" />
          </Route>
          <Route path={"/application/:friendlyId/found-a-property"}>
            <NurturePropertyDetails />
          </Route>
          <Route path="/application/:friendlyId">
            <Application />
          </Route>
          <Route path={"/magic-login"}>
            <MagicTokenLogin />
          </Route>
          <Route path={"/request-magic-link"}>
            <RequestMagicLink />
          </Route>
          <Route path={"/reset-password"}>
            <ResetPassword />
          </Route>
          <Route path={"/new-password"}>
            <NewPassword />
          </Route>
          <Route path={"/mortgagescore"}>
            <SentryErrorer path="/mortgagescore" redirect="/dashboard" />
          </Route>
          <Route path={"/survey-login"}>
            <SentryErrorer path="/survey-login" redirect="/dashboard" />
          </Route>
          <Route path={"/confirmation"}>
            <SentryErrorer path="/confirmation" redirect="/dashboard" />
          </Route>
          <Route path={"/callbackrequested"}>
            <SentryErrorer path="/callbackrequested" redirect="/dashboard" />
          </Route>
          <Route path={"/schedule/:applicationId"}>
            <SentryErrorer path="/schedule/:applicationId" redirect="/dashboard" />
          </Route>
          <Route path={"/login-from-session/:token/:redirect"}>
            <LoginFromSession />
          </Route>
          <Route
            path={"/book/:applicationId"}
            render={(props) => (
              <SentryErrorer
                path="/book/:applicationId"
                doRedirect={() => {
                  window.location.href = `/results/${props.match.params.applicationId}/crm${window.location.search}`
                }}
              />
            )}
          ></Route>
          <Route path={"/book-from-table/:applicationId"}>
            <SentryErrorer path="/book-from-table/:applicationId" redirect="/dashboard" />
          </Route>
          <Route path={"/reschedule/:applicationId/"}>
            <SentryErrorer path="/reschedule/:applicationId" redirect="/dashboard" />
          </Route>
          <Route path={"/recommended/:id"}>
            <CallRecommendations />
          </Route>
          <Route path={"/specific-rec/:token/:timestamp"}>
            <RecommendationPreview />
          </Route>
          <Route path={"/rec-preview/:token"}>
            <RecommendationPreview />
          </Route>
          <Route path={"/s/:code"}>
            <ShortCodeRedirect />
          </Route>
          <Route path={"/rate-check-request"}>
            <RateCheckRequest />
          </Route>
          <Route path={"/protection-review"}>
            <ProtectionReviewLandingPage />
          </Route>
          <Route path={"/rate-check-promise"}>
            <RateCheckPromise />
          </Route>
          <Route path={"/500"}>
            <GenericError />
          </Route>
          <RedirectToDash />
        </Switch>
      </Router>
      <CookieBanner />
    </ErrorBoundary>
  )
}

function RedirectToDash() {
  const history = useHistory()

  useEffect(() => {
    history.replace(`/dashboard${window.location.search}`)
  }, [])

  return <></>
}

// TODO: Make this useful for a user
function ErrorFallback() {
  return <>An error has occurred</>
}
