import React from "react"
import styled from "styled-components"
import devices from "styles/devices"
import { useHistory } from "react-router-dom"
import { Icon, IconType } from "components/atoms/Icon"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory, EventLabel } from "models/GoogleAnalytics"

const ContentBoxStyle = styled.div`
  border-radius: 12px;
  background: white;
  padding: 56px 25px;
  width: 85%;
  margin: 0 auto;

  @media ${devices.bp_sm} {
    width: 550px;
    padding: 56px 66px;
    margin-top: calc(20vh - 100px);
  }

  h1 {
    margin-bottom: 30px;
  }

  h4 {
    font-size: 20px;
  }

  p {
    margin-top: 10px;
    line-height: 1.6em;
  }
`

const BackButton = styled.div`
  display: flex;
  font-weight: 600;
  margin-bottom: 20px;
  cursor: pointer;
  align-items: center;
`

const ButtonText = styled.div`
  margin-left: 6px;
  margin-top: 2px;
`

type Props = {
  children: React.ReactNode
  showBackButton?: boolean
}

export const ContentBox = ({ children, showBackButton = true }: Props) => {
  const history = useHistory()
  return (
    <ContentBoxStyle>
      {showBackButton && (
        <BackButton
          onClick={() => {
            LogGaEvent({
              action: EventAction.buttonClicked,
              event_category: EventCategory.functionalInteraction,
              event_label: EventLabel.back,
            })
            window.location.href = "/login"
          }}
        >
          <Icon type={IconType.Back} />
          <ButtonText>Back to login</ButtonText>
        </BackButton>
      )}
      {children}
    </ContentBoxStyle>
  )
}
