export type ProductDetails = {
  AdverseTierLevel: number
  ApplicantTypeConditions?: string
  ApplyOnline: boolean
  AprLenders: number
  ArrangementFee: number
  AvailableToFTB?: boolean
  AvailableToPurchase?: boolean
  AvailableToRemortgage?: boolean
  BookingFee: number
  BrokerFee: number
  Cashback: number
  CHAPSFee: number
  // ClickoutLink: ClickoutLink[]
  DeedsReleaseFee: number
  DepositMinRequired: number
  DisbursementFee: number
  EarlyRepaymentChargeAppliesUntil: string
  EarlyRepaymentChargePeriodSortField: string
  FeesTotal: number
  FreeLegalFees: boolean
  FullRateDescription?: string
  GreenEcoProduct: boolean
  HelpToBuy?: boolean
  HigherLendingCharge: number
  Inheritance: boolean
  InitialMonthlyPayment: number
  InitialMonthlyPaymentIncExistingMortgage: number
  InitialPayRate: number
  InitialRatePeriod: string
  InitialRatePeriodMonths: number
  InitialRatePeriodPrecise: string
  InterestOnlyAcceptable: boolean
  LegalFee: number
  // Lender: ILenderResult
  LenderCode: string
  LenderName: string
  LenderProductReference: string
  LenderTelNo?: string
  LenderWebAddress?: string
  LoanRequiredIncludingFees: number
  MaxLoan: number
  MinLoan: number
  MaxLoanAvailable: number
  MaxLTVAvailable: number
  MaxOverpaymentPercentNoERC: number
  MonthlyPaymentAfterInitialPeriod: number
  MortgageClass: string
  MortgageDischargeFee: number
  NetProcFee: number
  OptionalPartialRepayment: boolean
  OverpaymentsAllowed: boolean
  PackagerCode: string
  PackagerName: string
  PartInvestmentAcceptable: boolean
  PreApproved: boolean
  ProcFee: number
  ProductAvailability:
    | "" // I've seen products with this
    | "All" // ?
    | "Direct Only" // ?
    | "Exclusive" // ?
    | "Intermediary" // ?
    | "Intermediaries Only" // I've seen products with this
    | "Exclusive Intermediaries Only" // I've seen products with this
  ProductCode: string
  ProductMatchStatus: string
  ProductName: string
  ProductType: string
  RejectReasons: string
  RemoNewBusiness: boolean
  RepaymentAcceptable: boolean
  SequenceNum: number
  StandardVariableRate: number
  SteppedRate?: boolean
  TrueCost: number
  TrueCostBalanceOutstanding: number
  TrueCostBalanceOutstandingIncExistingMortgage: number
  TrueCostFullTerm: number
  TrueCostFullTermIncExistingMortgage: number
  TrueCostIncExistingMortgage: number
  TrueCostInitialPeriodIncExistingMortgage: number
  TrueCostOverInitialPeriod: number
  TotalInterestPayable: number
  USPs: string[]
  ValuationFee: number
  InitialMonthlyPaymentIncludingFees: number
}
type LenderCode =
  | "001"
  | "003"
  | "ACC"
  | "ALD"
  | "ATM"
  | "BAA"
  | "BBB"
  | "BBU"
  | "BBV"
  | "BCO"
  | "BCV"
  | "BDA"
  | "BFU"
  | "BHI"
  | "BHP"
  | "BHX"
  | "BIP"
  | "BLF"
  | "BLH"
  | "BME"
  | "BMF"
  | "BMH"
  | "BMN"
  | "BMO"
  | "BMS"
  | "BNE"
  | "BNR"
  | "BNT"
  | "BNW"
  | "BOI"
  | "BPG"
  | "BPK"
  | "BPR"
  | "BSA"
  | "BSO"
  | "BST"
  | "BWO"
  | "CAM"
  | "CAN"
  | "CCN"
  | "CHL"
  | "CHY"
  | "CLY"
  | "CNT"
  | "CNW"
  | "CTS"
  | "DAN"
  | "DGF"
  | "DUD"
  | "EOS"
  | "EQF"
  | "FAM"
  | "FHL"
  | "FLE"
  | "FTB"
  | "GOD"
  | "GRH"
  | "HEC"
  | "HLT"
  | "HSB"
  | "HTB"
  | "ITB"
  | "JUS"
  | "KBS"
  | "KEY"
  | "KMC"
  | "LAG"
  | "LDB"
  | "LEE"
  | "LIM"
  | "LIV"
  | "MET"
  | "MFS"
  | "MOL"
  | "MQU"
  | "MTF"
  | "NBS"
  | "NTN"
  | "PAR"
  | "PEP"
  | "PET"
  | "POS"
  | "PRE"
  | "SBI"
  | "SBS"
  | "SEL"
  | "SKI"
  | "SOF"
  | "SRB"
  | "SWA"
  | "TBS"
  | "TIP"
  | "TML"
  | "TSB"
  | "UTB"
  | "VBS"
  | "VDA"
  | "WBB"
  | "WBC"
  | "WTO"
  | "YBS"
  | "ZPR"

type MortgageClass = "Fixed" | "Variable" | "Capped" | "LiborLinked" | "Discount" | "Tracker"

type MortgageType =
  | "Bridging_Loan"
  | "Buy_To_Let"
  | "Commercial"
  | "Equity_Release"
  | "Let_To_Buy"
  | "Right_To_Buy"
  | "Secured_Loan_BTL"
  | "Secured_Loan_Let_To_Buy"
  | "Secured_Loan_Shared_Ownership"
  | "Secured_Loan"
  | "Self_Build"
  | "Shared_Equity"
  | "Shared_Ownership"
  | "Standard_Or_Shared_Equity"
  | "Standard"

type PaymentMethod =
  | "Endowment"
  | "Individual_Savings_Account"
  | "Inheritance"
  | "Interest_Only"
  | "MortgageLinkedInvestment"
  | "Part_Investment"
  | "Pension"
  | "Repayment"
  | "RevertToCapitalRepayment"
  | "Sale_Of_Other_Property"
  | "Sale_Of_Property"
  | "SaleOfNonPropertyAssets"

type MortgageReason = "Purchase" | "Remortgage"

export enum ILRState {
  Found = "found",
  Expired = "expired",
  Declined = "declined",
  Progressing = "progressing",
  Completed = "completed",
  Cancelled = "cancelled",
}

type DateTimeStr = string

export type ProductDetailsRequest = {
  currentLenderCode: LenderCode | null
  loanAmount: number
  mortgageClass: MortgageClass
  mortgageReason: MortgageReason
  mortgageType: MortgageType
  paymentMethod: PaymentMethod
  productCode: string
  propertyValue: number
  salesforceQuoteLineItemId?: string
  termMonths: number
  addFeesToLoan: boolean
}

export type IdentifiedLowerRate = {
  createdAt: DateTimeStr
  id: string
  productDetails: ProductDetails
  rateCheckId: string
  status: ILRState
}

export type RateCheckInfo = {
  customerProductDetails: ProductDetails
  lastChecked: DateTimeStr
  productInput: ProductDetailsRequest
  rateLastChanged: DateTimeStr | null
}

export type RateCheckBody = {
  historicalIdentifiedLowerRates: IdentifiedLowerRate[]
  identifiedLowerRate: IdentifiedLowerRate
  rateCheck: RateCheckInfo
}

export type QuoteItemSchema = {
  displayName: string
  amount: number
}

export type Country = "England" | "Scotland" | "Wales" | "NorthernIreland"

export type QuoteAddress = {
  line1?: string
  line2?: string
  town?: string
  county?: string
  postcode?: string
  country?: Country
}

export type QuoteCase = {
  address: QuoteAddress
  coreAmount: number
  legalWorkSubTotal: number
  otherFeesTotal: number
  vatTotal: number
  disbursementsTotal: number
  grandTotal: number
  legalSupplements: QuoteItemSchema[]
  otherSupplements: QuoteItemSchema[]
  disbursements: QuoteItemSchema[]
  isCommissionVariable: boolean
  introducerCommissionMinimumAmount: number
  introducerCommissionMaximumAmount: number
  commissionAmount: number
}

export type Quote = {
  ref: string
  solicitor: string
  distance: number
  rating: number
  communicationRating: string
  legalFees: number
  total: number
  costsTotal: number
  depositAmount: number
  disbursementsTotal: number
  serviceLevelCode: string
  serviceProviderRef: string
  supplementsTotal: number
  vatTotal: number
  cases: QuoteCase[]
  smallPrint: string[]
  notes: string[]
  isShortlisted: boolean
  isPreferred: boolean
}

export type QuoteList = {
  generationDate: string
  expirationDate: string
  daysToExpiration: number
  isIndicative: boolean
  expired: boolean
  quotes: Quote[]
}

export type ConveyancingBody = QuoteList[]
