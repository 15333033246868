import React, { useMemo } from "react"
import styled from "styled-components"
import { TopNav } from "components/molecules/TopNav"
import { Button } from "mojo-storybook-v2-library"
import { ThemeConfig } from "styles/DefaultTheme"
import { EligibilityByExperian } from "components/atoms/Experian"
import { ContentGrid, LayoutGrid, desktop, mobile, tablet } from "components/_blueprint/Layout"
import { ComplianceFooter } from "./Footer"
import { P, SmallText, Text } from "./shared"

interface Props {
  getStarted: () => void
  isRemo: boolean
  theme: ThemeConfig
}

const BoldLink = styled.a`
  color: ${(props) => props.theme.primary};
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 0.5rem;
`

export const LanderContent: React.FC<Props> = ({ getStarted, isRemo, theme }) => (
  <>
    <TopNav showPoweredBy={theme.showPoweredBy} logoVariant={theme.poweredByVariant} />
    <LayoutGrid>
      <ContentGrid>
        <Copy getStarted={getStarted} isRemo={isRemo} theme={theme} />
        <Img src={theme.illustrations.lander} />
      </ContentGrid>
      <ComplianceFooter theme={theme} />
    </LayoutGrid>
  </>
)

const Copy = ({ getStarted, isRemo, theme }: Props) => (
  <Text>
    <WideStack>
      <h1>
        {(typeof theme.landerHeader === "function" && theme.landerHeader(isRemo)) ||
          theme.landerHeader}
      </h1>
      {theme.landerLeadText && <P>{theme.landerLeadText}</P>}
      <CustomList>
        <CustomListItem>{theme.landerBullet1}</CustomListItem>
        <CustomListItem>{theme.landerBullet2}</CustomListItem>
        <CustomListItem>{theme.landerBullet3}</CustomListItem>
        {theme.landerBullet4 && <CustomListItem>{theme.landerBullet4}</CustomListItem>}
        {theme.landerBullet5 && <CustomListItem>{theme.landerBullet5}</CustomListItem>}
      </CustomList>
      {theme.landerSecondaryText && <P>{theme.landerSecondaryText}</P>}
      <ButtonRow>
        <Button onClick={getStarted} color={theme?.primary} id={"btn-get-started"}>
          {theme.landerCTA ?? "Get Started"}
        </Button>
      </ButtonRow>
      <BoldLink href={"/login"}>Returning user? Log in here</BoldLink>
      <SmallText complianceText={theme.landerComplianceText} />
      <EligibilityByExperian />
    </WideStack>
  </Text>
)

const WideStack = styled.div({
  display: "grid",
  gap: "2rem",
})

const CustomList = styled.ul({
  listStyle: "none",
  display: "grid",
  gap: 24,
  margin: 0,
})

const CustomListItem = styled.li`
  position: relative;
  text-align: left;
  margin-bottom: 0;

  &:after {
    border-left: ${(props) => props.theme.shade[100]} 2px solid;
    border-bottom: ${(props) => props.theme.shade[100]} 2px solid;
    left: -30px;
    top: 9px;
    width: 8px;
    height: 4px;
    margin: auto;
    position: absolute;
    content: "";
    transform: rotate(-45deg);
  }

  &:before {
    background: ${(props) => props.theme.primary};
    left: -34px;
    top: 4px;
    margin: auto;
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
`

const Img = styled.img({
  maxWidth: "90%",
  alignSelf: "center",
  [mobile]: {
    display: "none",
  },
  [tablet]: {
    gridColumnEnd: "span 3",
    gridColumnStart: 4,
  },
  [desktop]: {
    gridColumnEnd: "span 6",
    gridColumnStart: 7,
  },
})

const ButtonRow = styled.div({
  [mobile]: {
    "& button": {
      width: "100%",
    },
  },
  [tablet]: {
    "& button": {
      width: "max-content",
    },
  },
  [desktop]: {
    "& button": {
      width: "max-content",
    },
  },
})
