import React, { useEffect, useMemo, useState } from "react"
import { DecodeJwt } from "lib/Jwt"
import { SetToken } from "lib/Authentication"
import axios from "axios"
import { LoadingTemplate } from "templates/LoadingTemplate"
import { useHistory } from "react-router-dom"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"

export function MagicTokenLogin() {
  const params = new URLSearchParams(window.location.search)
  const history = useHistory()

  const [hasToken, setHasToken] = useState(true)
  const [hasExpired, setHasExpired] = useState(false)

  useTrackNavigatedToPage("/magic-login")

  useEffect(() => {
    async function init() {
      try {
        if (!params.has("token")) {
          setHasToken(false)
          return
        }

        const magicLoginToken = params.get("token")
        const decoded = DecodeJwt<MagicToken>(magicLoginToken)

        if (decoded.exp < Math.floor(Date.now() / 1000)) {
          setHasExpired(true)
          return
        }

        const authToken = await GenerateJwtFromMagicToken(magicLoginToken)
        SetToken(authToken)
        LogGaEvent({
          action: EventAction.loginSuccess,
          event_category: EventCategory.functionalInteraction,
          event_label: "Magic Login",
        })

        if (params.has("redirect")) {
          const redirect = Buffer.from(params.get("redirect"), "base64").toString()
          LogGaEvent({
            action: EventAction.loginRedirect,
            event_category: EventCategory.functionalInteraction,
            event_label: redirect,
          })
          history.push(redirect)
        } else {
          history.push("/dashboard")
        }
      } catch (_) {
        // This specifically redirects to login without a redirect query param
        // because if the token failed it means the token didn't expire, but was invalid
        window.location.href = `/login`
      }
    }
    init()
  }, [])

  const loginLink = useMemo(() => {
    const redirect = params.has("redirect")
      ? `${Buffer.from(params.get("redirect"), "base64").toString()}`
      : "/dashboard"
    return `/login?redirect=${encodeURIComponent(
      `${window.location.protocol}//${window.location.hostname + redirect}`
    )}`
  }, [])

  let message = null
  let isLoading = true

  if (hasExpired) {
    message = (
      <strong>
        Your login token has expired. <a href={loginLink}>Click here</a> to go to the login page and
        try again.
      </strong>
    )
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "Your login token has expired",
    })
    isLoading = false
  } else if (!hasToken) {
    message = (
      <strong>
        Looks like you've got here by mistake. <a href={loginLink}>Click here</a> to go to the login
        page.
      </strong>
    )
    LogGaEvent({
      action: EventAction.componentDisplay,
      event_category: EventCategory.siteInteraction,
      event_label: "Looks like you've got here by mistake",
    })
    isLoading = false
  }

  return <LoadingTemplate message={message} isLoading={isLoading} />
}

async function GenerateJwtFromMagicToken(magicToken: string) {
  // this calls a newer version of the auth API which sets a HttpOnly cookie for session
  try {
    await axios.get(`${process.env.PUBLIC_AUTH_API}/magic-login?magicToken=${magicToken}`, {
      withCredentials: true,
    })
  } catch (err) {
    console.log(err)
  }
  const response = await axios.post(`${process.env.MY_MOJO_API}/auth/magic-token`, { magicToken })
  return response.data.token
}

type MagicToken = {
  email: string
  userId: number
  exp: number
}
