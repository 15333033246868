import React from "react"
import styled from "styled-components"
import { Paragraph } from "components/_blueprint/Typography"
import { Accordion, AccordionDataSection } from "pages/Application/Eligibility/Coach/Accordion"

const Container = styled.div`
  margin: "2rem 0";
  h3 {
    font-weight: 700;
    display: inline-block;
    font-size: 22px;
    padding-bottom: 0.2em;
    border-bottom: 4px solid blue;
    margin-bottom: 1.4em;
  }
`

export const ConveyancingFaqs = () => (
  <Container>
    <h3>Conveyancing FAQs</h3>
    <Accordion data={FaqData} />
  </Container>
)

const FaqSection = styled.div({
  lineHeight: "1.5em",
})

const FaqP = styled(Paragraph)({
  fontSize: "16px",
})

const FaqData: AccordionDataSection[] = [
  {
    name: "What even is conveyancing, and is it necessary?",
    id: "faq-1",
    trackingLabelPrefix: "Conveyancing-FAQs",
    page: (
      <FaqSection>
        <FaqP>
          Conveyancing is the legal side of buying or selling a house (or remortgaging to another
          lender).
        </FaqP>
        <FaqP>
          The main role of a conveyancer is to legally transfer the ownership of a property from one
          person to another. The exact nature of the work they do changes from case to case, but
          generally they look at:
        </FaqP>
        <ul>
          <li>
            <FaqP>
              Checking the Land Registry to find any boundary issues and what land and buildings are
              included in the sale
            </FaqP>
          </li>
          <li>
            <FaqP>
              Creating contracts for the transfer of ownership and checking clauses included by
              other parties in the transaction
            </FaqP>
          </li>
          <li>
            <FaqP>
              Guarding against potential fraud issues if the property is used as part of an illegal
              transaction
            </FaqP>
          </li>
          <li>
            <FaqP>
              Checking the property has all the right certificates and approvals and they have been
              properly registered
            </FaqP>
          </li>
          <li>
            <FaqP>Transferring money at exchange and completion</FaqP>
          </li>
        </ul>
        <FaqP>
          Lenders will often change their rates based on a number of factors. Sometimes it’s to
          attract or retain customers in a competitive market. Other times it’s in response to
          Government policies or changes to the Bank of England base rate.
        </FaqP>
      </FaqSection>
    ),
  },
  {
    name: "Free legals vs using Mojo",
    id: "faq-2",
    trackingLabelPrefix: "Conveyancing-FAQs",
    page: (
      <FaqSection>
        <FaqP>
          Many mortgage deals offer free legal fees – particularly if you are remortgaging.
        </FaqP>
        <FaqP>Obviously, this may help you save money and can be a good option.</FaqP>
        <FaqP>
          However, many people like to believe that their conveyancer is working for them and not
          their lender.
        </FaqP>
      </FaqSection>
    ),
  },
  {
    name: "Do you get commission for recommending a conveyancer",
    id: "faq-3",
    trackingLabelPrefix: "Conveyancing-FAQs",
    page: (
      <FaqSection>
        <FaqP>Mojo doesn't recommend any conveyancer.</FaqP>
        <FaqP>
          We work with a company called Smoove to bring you a selection of conveyancing quotes. It
          is your choice to choose any conveyancer you wish. If you do opt for a conveyance via a
          quote provided to you by Mojo, Smoove will pay Mojo a referral fee.
        </FaqP>
        <FaqP>
          Mojo doesn't charge any broker fees and we are bringing you the conveyancing quotes in an
          effort to make the mortgage process easier and more transparent for you.
        </FaqP>
      </FaqSection>
    ),
  },
  {
    name: "How long does conveyancing take?",
    id: "faq-4",
    trackingLabelPrefix: "Conveyancing-FAQs",
    page: (
      <FaqSection>
        <FaqP>
          Conveyancing ends once the new ownership of a property is registered at the Land Registry.
          It starts as soon as you have an official offer accepted.
        </FaqP>
        <FaqP>
          There are two major parts of conveyancing: exchange and completion – and these can occur
          on the same day. However, typically you'll find there are 2 weeks between the exchange and
          completion.{" "}
        </FaqP>
        <FaqP>
          In reality, lots of work is done prior to these stages and the whole process usually takes
          2 to 3 months depending on the size of your chain.
        </FaqP>
        <FaqP>
          Always make your conveyancer aware of any completion date you have in mind at the start of
          the process.
        </FaqP>
      </FaqSection>
    ),
  },
  {
    name: "How much are conveyancing fees?",
    id: "faq-5",
    trackingLabelPrefix: "Conveyancing-FAQs",
    page: (
      <FaqSection>
        <FaqP>There are 2 types of costs you have to pay with conveyancing:</FaqP>
        <ol>
          <li>
            <FaqP>The legal fees – paying the conveyancer for the work they do</FaqP>
          </li>
          <li>
            <FaqP>
              The disbursement – these are extra costs that the conveyancer has to charge to do
              their job, namely 3rd parties charges for certain services like searches and
              registering your property. Stamp duty can also be listed as a disbursement
            </FaqP>
          </li>
        </ol>
        <FaqP>
          Some conveyancing fees only apply to sellers and some only apply to buyers. It can be
          tough to understand what is good value, which is why Mojo has sourced a selection of
          quotes based on your specific circumstances and conveyancing needs.
        </FaqP>
        <FaqP>Quotes are based on a number of factors including:</FaqP>
        <ul>
          <li>
            <FaqP>Whether your property is leasehold or freehold</FaqP>
          </li>
          <li>
            <FaqP>The price of your property</FaqP>
          </li>
          <li>
            <FaqP>If you’re buying a Shared Ownership, Help to Buy or Right to Buy property</FaqP>
          </li>
          <li>
            <FaqP>The number of additional searches needed</FaqP>
          </li>
        </ul>
      </FaqSection>
    ),
  },
  {
    name: "When do you pay conveyancing fees?",
    id: "faq-6",
    trackingLabelPrefix: "Conveyancing-FAQs",
    page: (
      <FaqSection>
        <FaqP>
          Normally, conveyancing fees are paid at the completion of the purchase or remortgaging
          process.
        </FaqP>
      </FaqSection>
    ),
  },
]
