import { mobile } from "components/_blueprint/Layout"
import Tooltip from "components/_blueprint/Tooltip"
import * as React from "react"
import styled from "styled-components"

export enum QuoteDetailEnum {
  DISTANCE = "Distance",
  RATING = "Rating",
  COMMUNICATION = "Communication",
  LEGAL_FEES = "Legal fee",
  TOTAL = "Total",
}

export const QuoteDetail = ({ type, data }: { type: QuoteDetailEnum; data: string }) => {
  const selectTooltipContent = (type: QuoteDetailEnum) => {
    switch (type) {
      case QuoteDetailEnum.DISTANCE:
        return "This is how far from the property the solicitor is based. Some people prefer a solicitor close by, while for others it doesn’t matter."
      case QuoteDetailEnum.RATING:
        return "This rating is made up from feedback from other users. Where no rating is shown, it simply means we don't have enough feedback to give a fair rating. If a provider has less than 20 ratings, we'll show the number of ratings to help you make an informed decision."
      case QuoteDetailEnum.COMMUNICATION:
        return "This measures how effectively a conveyancing firm uses their online portal to provide proactive and responsive communication."
      case QuoteDetailEnum.LEGAL_FEES:
        return "This is the provider's charge for doing their conveyancing work based on the information provided (excluding VAT)."
      case QuoteDetailEnum.TOTAL:
        return "This is the total cost, including the legal work and disbursements (including VAT). Disbursements are costs paid by a provider on behalf of the client, such as Stamp Duty and Land Registry fees."
      default:
        return null
    }
  }

  const content = selectTooltipContent(type)

  return (
    <QuoteDetailContainer>
      <QuoteDetailItem>
        <div>{type}</div>
        {content && <Tooltip content={content} />}
      </QuoteDetailItem>
      <QuoteDetailItem>
        <div>{data}</div>
      </QuoteDetailItem>
    </QuoteDetailContainer>
  )
}

const QuoteDetailContainer = styled.li({
  fontSize: "12px",
  display: "flex",
  justifyContent: "start",
  gap: "8px",
  padding: "8px 0px",
  flexDirection: "column",
  [mobile]: {
    padding: "8px 8px",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  "&:last-of-type": {
    borderRadius: "4px",
    background: "#073ed910",
    padding: "8px 24px",
    [mobile]: {
      padding: "8px",
    },
    "& > div": {
      justifyContent: "center",
    },
  },

  "& > div:last-of-type": {
    fontSize: "20px",
    fontWeight: 600,
  },
})

const QuoteDetailItem = styled.div`
  display: flex;
  align-items: center;
`
