import axios from "axios"
import {
  ContentContainer as BaseContentContainer,
  ContentGrid,
  desktop,
  mobile,
} from "components/_blueprint/Layout"
import { Paragraph } from "components/_blueprint/Typography"
import { Button } from "components/atoms"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { Notifications } from "components/atoms/Notifications"
import { useTrackNavigatedToPage } from "hooks/useTrackPageView"
import * as React from "react"
import { useEffect, useState } from "react"
import toast from "react-hot-toast/headless"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import devices from "styles/devices"
import { ConveyancingFaqs } from "./FAQ"
import { FilteredQuotes, filterQuotes } from "./filterQuotes"
import { QuoteCard } from "./QuoteCard"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { useSession } from "lib/auth/react"

export const ConveyancingPage = () => {
  const { friendlyId } = useParams<{ friendlyId: string }>()
  const [quoteList, setQuoteList] = useState<FilteredQuotes>(FilteredQuotes.EMPTY)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
    const { isLoading: sessionLoading } = useSession()

  useTrackNavigatedToPage("/application/conveyancing")

  useEffect(() => {
    if (friendlyId && !sessionLoading) {
      axios
        .get(`${process.env.MY_MOJO_API}/conveyancing/quote-list/${friendlyId}`, {
          withCredentials: true,
        })
        .then(({ data }) => {
          setQuoteList(filterQuotes(data))
          setIsError(false)
          setIsLoading(false)
        })
        .catch((error) => {
          console.error("Error:", error)
          setIsError(true)
          setIsLoading(false)
        })
    }
  }, [friendlyId, sessionLoading])

  useEffect(() => {
    if (quoteList.quotes.length == 0) {
      LogGaEvent({
        action: EventAction.buttonClicked,
        event_category: EventCategory.siteInteraction,
        event_label: "No quotes returned",
      })
    }
  }, [quoteList])

  useEffect(() => {
    if (quoteList.expired) {
      LogGaEvent({
        action: EventAction.buttonClicked,
        event_category: EventCategory.siteInteraction,
        event_label: "Quotes expired",
      })
    }
  }, [quoteList.expired])

  useEffect(() => {
    if (quoteList.isIndicative) {
      LogGaEvent({
        action: EventAction.buttonClicked,
        event_category: EventCategory.siteInteraction,
        event_label: "Quotes indicative",
      })
    }
  }, [quoteList.isIndicative])

  return (
    <ContentGrid>
      <ContentContainer>
        {isLoading ? (
          <LoaderContainer>
            <Loader size={150} theme={LoaderTheme.Outline} />
          </LoaderContainer>
        ) : (
          <>
            <HeaderContainer>
              <TitleContainer>
                <Header>Conveyancing</Header>
              </TitleContainer>
              {isError || quoteList.quotes.length == 0 ? (
                <TextContainer>
                  <Subheader>
                    If you’re buying a property or remortgaging with a new lender, then we can help
                    you find solicitors for conveyancing.
                  </Subheader>
                  <p>
                    Get in touch with your Mortgage Expert, who will be able to provide you with a
                    number of options from our panel of solicitors.
                  </p>
                </TextContainer>
              ) : (
                <TextContainer>
                  <Subheader>
                    We’ve sourced quotes from different conveyancers based on your personal
                    circumstances.
                  </Subheader>
                  <p>
                    Choose one that interests you. After that, your Mortgage Expert will provide
                    more details and discuss any next steps.
                  </p>
                  <p>
                    If you would like to see more quotes, please get in touch with your Mortgage
                    Expert
                  </p>
                </TextContainer>
              )}
            </HeaderContainer>
            {quoteList.quotes.length != 0 && (
              <>
                <QuotesContainer>
                  {quoteList.expired && (
                    <ExpiredBanner>
                      These conveyancing quotes have expired. If you would like an up to date list
                      of quotes, please contact your Mortgage Expert
                    </ExpiredBanner>
                  )}
                  {quoteList.isIndicative && (
                    <IndicativeBanner>
                      As you do not have an offer accepted on a property, we generated these quotes
                      using a generic post code in the country you are buying in. As such,{" "}
                      <b>prices quoted here are indicative</b>, and may change based on the property
                      you eventually purchase.{" "}
                    </IndicativeBanner>
                  )}
                  {quoteList.quotes.map((quote, idx) => (
                    <QuoteCard
                      quote={quote}
                      expirationDate={quoteList.expirationDate}
                      isIndicative={quoteList.isIndicative}
                      key={idx}
                      idx={idx}
                    />
                  ))}
                </QuotesContainer>
                <DisclaimerFooter />
              </>
            )}
            <ConveyancingFaqs />
            <Notifications notificationComponent={<RateSelectedToast />} />
          </>
        )}
      </ContentContainer>
    </ContentGrid>
  )
}

const ToastText = styled.div`
  width: 100%;

  @media screen and ${devices.bp_sm} {
    max-width: 70%;
  }
`

const ToastButton = styled(Button)`
  width: 100%;
  background: #1a3dd1;
  color: #fff;
  text-align: center;
  box-shadow: inset 0 -6px 0px #052ea3;

  @media screen and ${devices.bp_sm} {
    width: auto;
  }
`

const RateSelectedToast = ({ id, message }: { id?: string; message?: string }) => {
  return (
    <React.Fragment>
      <ToastText>{message}</ToastText>
      <ToastButton
        onClick={() => toast.dismiss(id)}
        width="auto"
        type={2}
        text="Got it"
        style={{
          alignSelf: "end",
        }}
      />
    </React.Fragment>
  )
}

const ContentContainer = styled(BaseContentContainer)({
  [desktop]: {
    gridColumnStart: 2,
    gridColumnEnd: "span 9",
  },
})

const TextContainer = styled.div({
  maxWidth: "584px",
  margin: "1rem 0",
})

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and ${devices.bp_xl} {
    width: 34em;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: rgba(59, 66, 88, 1);
    line-height: 24px;
  }
`

const Header = styled.h1`
  font-size: 39px;
  font-weight: 550;
  min-width: 60%;
`

const TitleContainer = styled.div({
  display: "flex",
  justifyContent: "space-between",

  [mobile]: {
    flexDirection: "column",
    justifyContent: "start",
  },
})

const Subheader = styled.h1`
  font-size: 18px;
  font-weight: 600;
`

const QuotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 24px;
`

const IndicativeBanner = styled.div`
  background: #fcc778;
  padding: 24px;
  border-radius: 8px;
`

const ExpiredBanner = styled.div`
  padding: 16px;
  gap: 8px;
  border-radius: 16px;
  border: 1px;
  color: #6d1b22;
  background: #fcf1ee;
  border: 1px solid #d0070d;
  font-weight: 600;
  font-size: 12px;
  line-height: 2em;
`

export const DisclaimerFooter = () => (
  <DisclaimerContainer>
    <Disclaimer size="caption">
      These quotes are provided by Smoove’s eConveyancer platform to help our customers to compare
      conveyancing providers.
    </Disclaimer>
    <Disclaimer size="caption">
      Please be aware, that prices are based on the information you have provided to Mojo and are
      subject to change
    </Disclaimer>
  </DisclaimerContainer>
)

const DisclaimerContainer = styled.div`
  margin-bottom: 72px;
`

const Disclaimer = styled(Paragraph)({
  textAlign: "center",
})
