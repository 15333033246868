import { ToCurrency } from "lib/CurrencyFormatter"
import React, { useState } from "react"
import ReactDOM from "react-dom"
import styled, { createGlobalStyle } from "styled-components"
import { Quote, QuoteAddress, QuoteCase, QuoteItemSchema } from "../types"
import { QuoteDetail, QuoteDetailEnum } from "./QuoteDetail"
//import { Button, ButtonType } from "components/atoms"
import { animated, useTransition } from "@react-spring/web"
import { mobile } from "components/_blueprint/Layout"
import Typography from "components/_blueprint/Typography"
import { IconStyleable, IconType } from "components/atoms/Icon"
import dayjs from "dayjs"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { tint } from "polished"
import { defaultTheme } from "styles/DefaultTheme"

const formatCustomerRating = (n: number) => {
  const rating = Math.round(n)
  return rating > 0 ? `${rating}%` : "Not yet rated"
}

const formatAddress = (address: QuoteAddress) => {
  const addessLines = `${address.line1}` + (address.line2 && `, ${address.line2}`)
  return `${addessLines}, ${address.town} ${address.postcode}`
}

type QuoteCardProps = {
  quote: Quote
  expirationDate: Date
  isIndicative?: boolean
  idx: number
}

export const QuoteCard = ({ quote, expirationDate, isIndicative, idx }: QuoteCardProps) => {
  const [detailsOpen, setDetailsOpen] = useState(false)

  const transitions = useTransition(detailsOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <>
      <QuoteWrapper selected={false} disable={false}>
        <QuoteHeader>
          <QuoteCardTitle>{quote.solicitor}</QuoteCardTitle>
          {quote.isPreferred && <PreferredPill>Preferred</PreferredPill>}
        </QuoteHeader>

        <QuoteContainer>
          <QuoteDetails aria-label={`${quote.solicitor} details`}>
            <QuoteDetail
              type={QuoteDetailEnum.DISTANCE}
              data={`${Math.round(quote.distance)} miles`}
            />
            <QuoteDetail type={QuoteDetailEnum.RATING} data={formatCustomerRating(quote.rating)} />
            <QuoteDetail
              type={QuoteDetailEnum.LEGAL_FEES}
              data={ToCurrency(quote.legalFees, "£0.00", 2)}
            />
            <QuoteDetail type={QuoteDetailEnum.TOTAL} data={ToCurrency(quote.total, "£0.00", 2)} />
          </QuoteDetails>
          <ActionContainer>
            {/*
            <Button id={"id"} type={ButtonType.Solid} text={"I'm interested"} disabled={true} />
            */}
            <Link
              href="#"
              onClick={() => {
                LogGaEvent({
                  action: EventAction.buttonClicked,
                  event_category: EventCategory.siteInteraction,
                  event_label: `Conveyancing details ${idx}`,
                })

                setDetailsOpen(true)
              }}
            >
              Tell me more
            </Link>
          </ActionContainer>
        </QuoteContainer>
      </QuoteWrapper>
      {transitions((style, active) => (
        <>
          {active && (
            <Portal>
              <PanelOverlay
                data-state="open"
                style={{
                  pointerEvents: "auto",
                  opacity: style.opacity.to([0, 1], [0, 0.4]),
                }}
                data-aria-hidden="true"
                aria-hidden="true"
              />
              <Panel
                role="dialog"
                id="radix-:r0:"
                aria-describedby="mojo-:r2:"
                aria-labelledby="mojo-:r1:"
                data-state="open"
                tabIndex={-1}
                style={{
                  pointerEvents: "auto",
                  opacity: style.opacity.to([0, 1], [0, 1]),
                  transform: style.opacity
                    .to([0, 1], [100, 0])
                    .to((value) => `translateX(${value}px)`),
                }}
              >
                <button
                  style={{
                    color: defaultTheme.colors.primary.one,
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    padding: 0,
                  }}
                  onClick={() => {
                    setDetailsOpen(false)
                  }}
                  aria-label="Close"
                >
                  <IconStyleable
                    fillDefault={defaultTheme.colors.primary.one}
                    width={24}
                    height={24}
                    type={IconType.Cross}
                  />
                  Close
                </button>
                <Typography.H5
                  id="mojo-:r1:"
                  style={{
                    marginTop: "2rem",
                  }}
                >
                  {quote.solicitor}
                </Typography.H5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  {quote.cases.length == 1 ? (
                    <FeeBreakdown
                      quoteCase={quote.cases[0]}
                      title={
                        isIndicative
                          ? "Purchase Address to be Confirmed"
                          : `Purchase of ${formatAddress(quote.cases[0].address)}`
                      }
                    />
                  ) : (
                    quote.cases.length > 1 && (
                      <>
                        <FeeBreakdown
                          quoteCase={quote.cases[0]}
                          title={`Sale of ${formatAddress(quote.cases[0].address)}`}
                        />
                        <FeeBreakdown
                          quoteCase={quote.cases[1]}
                          title={
                            isIndicative
                              ? "Purchase Address to be Confirmed"
                              : `Purchase of ${formatAddress(quote.cases[1].address)}`
                          }
                        />
                        <QuoteTotalPanel total={quote.total} />
                      </>
                    )
                  )}
                  <RatingPanel label="Customer rating" value={formatCustomerRating(quote.rating)} />
                  <Disclaimers>
                    <Typography.Text>
                      This quote is valid until {dayjs(expirationDate).format("MMMM D, YYYY")}
                    </Typography.Text>
                    <LegalText title="Notes" lines={quote.notes} />
                    <LegalText title="Terms & Conditions" lines={quote.smallPrint} />
                  </Disclaimers>
                </div>
              </Panel>
            </Portal>
          )}
          {/* We want hiding body overflow to be instant */}
          {detailsOpen && <HideBodyOverflow />}
        </>
      ))}
    </>
  )
}

// this portal is required to render the modal outside of the current DOM flow
// or it will clash with z-index of the main body content
const Portal = ({ children, className = "root-portal", el = "div" }) => {
  const [container] = React.useState(() => {
    // This will be executed only on the initial render
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    return document.createElement(el)
  })

  React.useEffect(() => {
    container.classList.add(className)
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  }, [])

  return ReactDOM.createPortal(children, container)
}

export const ValueContainer = styled.div({
  display: "flex",
  alignItems: "baseline",
  gap: 8,
})

const QuoteWrapper = styled.div<{ selected: boolean; disable: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;

  border-radius: 16px;
  background: #fff;
  padding: 24px;
  border: ${({ selected }) => (selected ? "2px solid orange" : "none")};
  opacity: ${({ disable }) => (disable ? "0.7" : "1")};
`

const QuoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const QuoteCardTitle = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PreferredPill = styled.div`
  text-align: center;
  line-height: 21.6px;
  font-weight: 600;
  font-size: 12px;
  background: #fbbb5b;
  color: #000;
  padding: 4px 16px;
  border-radius: 360px;
`

const QuoteContainer = styled.div({
  display: "flex",
  gap: "16px",
  [mobile]: {
    flexDirection: "column",
  },
})

const QuoteDetails = styled.ul({
  flex: "1",
  display: "flex",
  borderRadius: "8px",
  padding: "12px 12px 12px 24px",
  background: "#f3f5fc",
  justifyContent: "space-between",
  margin: 0,
  [mobile]: {
    padding: "12px",
    flexDirection: "column",
  },
})

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Link = styled.a`
  text-align: center;
  color: #073ed9;
  font-weight: 600;
`

// ---------------------
// Quote details panel

type FeeBreakdownProps = {
  quoteCase: QuoteCase
  title?: string
  showAddress?: boolean
}

export const FeeBreakdown = ({ quoteCase, title }: FeeBreakdownProps) => {
  return (
    <PanelContainers>
      {title && (
        <Typography.Text weight={600} size={"large"}>
          {title}
        </Typography.Text>
      )}
      <Typography.Text weight={600}>Fee breakdown</Typography.Text>
      <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
        <FeeLineItems
          title="Legal work"
          items={[
            { displayName: "Core conveyancing", amount: quoteCase.coreAmount },
            ...quoteCase.legalSupplements,
          ]}
          total={quoteCase.legalWorkSubTotal}
        />

        <FeeLineItems
          title="Other fees"
          items={quoteCase.otherSupplements}
          total={quoteCase.otherFeesTotal}
        />
        <FeeLineItems
          title="VAT"
          items={[{ displayName: "Applied at 20%", amount: quoteCase.vatTotal }]}
          total={quoteCase.vatTotal}
        />
        <FeeLineItems
          title="Disbursements (No VAT/VAT inc.)"
          items={quoteCase.disbursements}
          total={quoteCase.disbursementsTotal}
        />

        <SpacedContainer>
          <Typography.Text size="large" weight={700}>
            Total
          </Typography.Text>
          <Typography.Text size="large" weight={700}>
            {ToCurrency(quoteCase.grandTotal, "£0.00", 2)}
          </Typography.Text>
        </SpacedContainer>
      </div>
    </PanelContainers>
  )
}

type FeeLineItemsProps = {
  title: string
  items: QuoteItemSchema[]
  total: number
}

export const FeeLineItems = ({ title, items, total }: FeeLineItemsProps) => {
  return (
    items.length > 0 && (
      <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography.Text size="small" weight={700}>
            {title}
          </Typography.Text>
          {items.map((item, i) => {
            return (
              <SpacedContainer key={i}>
                <Typography.Text size="small">{item.displayName || "Other"}</Typography.Text>
                <Typography.Text size="small">
                  {ToCurrency(item.amount, "£0.00", 2)}
                </Typography.Text>
              </SpacedContainer>
            )
          })}
        </div>
        <SpacedContainer>
          <Typography.Text size="small" weight={600}>
            Sub Total
          </Typography.Text>
          <Typography.Text size="small" weight={600}>
            {ToCurrency(total, "£0.00", 2)}
          </Typography.Text>
        </SpacedContainer>
      </div>
    )
  )
}

type LegalTextProps = {
  title: string
  lines: string[]
}

export const LegalText = ({ title, lines }: LegalTextProps) => {
  return (
    <>
      <Typography.Text size="large" weight={600}>
        {title}
      </Typography.Text>
      <ol
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          marginTop: "0",
          marginBottom: "0",
        }}
      >
        {lines.map((n, i) => (
          <li key={i}>
            <Typography.Text>
              <span
                dangerouslySetInnerHTML={{
                  __html: n.replace(/^\d+\s*[-\\.)]?\s+/i, ""),
                }}
              />
            </Typography.Text>
          </li>
        ))}
      </ol>
    </>
  )
}

type QuoteTotalPanel = {
  total: number
}

const QuoteTotalPanel = ({ total }: QuoteTotalPanel) => {
  return (
    <PanelContainers
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography.Text size={"large"} weight={600} style={{ flex: "1 1 0px" }}>
        Sale & purchase conveyancing total
      </Typography.Text>
      <Typography.Text
        weight={600}
        size={"large"}
        style={{
          textAlign: "right",
          alignSelf: "flex-end",
          flex: "1 1 0px",
          whiteSpace: "nowrap",
        }}
      >
        {ToCurrency(total, "£0.00", 2)}
      </Typography.Text>
    </PanelContainers>
  )
}

type RatingPanelProps = {
  label: string
  value: string
}

const RatingPanel = ({ label, value }: RatingPanelProps) => {
  return (
    <PanelContainers
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography.Text weight={600}>{label}</Typography.Text>
      <Typography.Text
        size="small"
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </Typography.Text>
    </PanelContainers>
  )
}

const PanelOverlay = styled(animated.div)`
  background-color: ${(props) => props.theme.primaryThree};
  opacity: 0.4;
  position: fixed;
  inset: 0px;
  z-index: 1001;
`

const Panel = styled(animated.div)`
  background-color: ${(props) => props.theme.primaryFour};
  border-radius: ${(props) => props.theme.radius.large} 0 0 ${(props) => props.theme.radius.large};
  padding: 48px 48px 80px 24px;
  max-width: 590px;
  width: 100%;

  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1002;

  overflow-y: auto;
`

const PanelContainers = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 1rem;

  background-color: ${(props) => tint(0.95, props.theme.colors.primary.one)};
  border-radius: 8px;
  padding: 32px 24px;
`

const SpacedContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Disclaimers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 14px;
  padding: 0 24px;
`

const HideBodyOverflow = createGlobalStyle`
	body {
		overflow-y: hidden !important;;
	}
`
