import React, { useEffect, useRef, useState } from "react"
import Shield from "jsx:assets/images/icons/Shield.svg"

import { Accordion, AccordionDataSection } from "pages/Application/Eligibility/Coach/Accordion"
import { UploadBox } from "components/molecules/UploadBox"
import { MojoListItem } from "components/molecules/MojoListItem"
import { FILESTACK_CONTAINER } from "../../../../../Config"
import { FilestackUploadPicker } from "./FilestackUploadPicker"
import { Applicant, Document, DocumentType, FullApplication } from "models/FullApplication"
import { UploadDocumentMetaData } from "lib/Document"
import { LogError } from "../../../../../Logging"
import { DocumentStatus } from "components/molecules/DocumentStatus"
import { Loader, LoaderTheme } from "components/atoms/Loader"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { Faqs } from "pages/Application/Eligibility/Status/Faqs"
import QRCode from "react-qr-code"
import Smartlook from "lib/Smartlook"
import {
  AccordionContent,
  AccordionContentInfo,
  AccordionWrapper,
  FaqContainer,
  Header,
  HeaderContent,
  HeaderContentLHS,
  HeaderContentRHS,
  LoaderContainer,
  OtherDocuments,
  QRCodeContainer,
  ReassuranceBanner,
  ReassuranceBannerTextContainer,
  Root,
  ShieldContainer,
  SubHeader,
  UploadBoxContainer,
} from "./components"
import { useSession, useSessionQR } from "lib/auth/react"

interface Props {
  friendlyId: string
  application: FullApplication
  refreshApplication?: (applicationToUpdate?: FullApplication, silent?: boolean) => Promise<void>
}

const qrUtmParams = new URLSearchParams({
  utm_source: "mymojo",
  utm_medium: "docs",
  utm_campaign: "qr",
}).toString()

type PickerState = {
  applicant: Applicant
  documentType: DocumentType
}

type DocumentsByType = Record<DocumentType, Document[]>
const emptyDocsByType: DocumentsByType = { BankStatements: [], ID: [], Income: [], Other: [] }
const sortByType = (docs: Document[]): DocumentsByType =>
  docs.reduce<DocumentsByType>(
    (acc, doc) => ({
      ...acc,
      [doc.docType]: acc[doc.docType].concat(doc),
    }),
    emptyDocsByType
  )

export const Documents = ({ friendlyId, application }: Props) => {
  const { data: qrData, isLoading: qrIsLoading } = useSessionQR({ redirect: window.location.href })
  const { data } = useSession()
  const faqRef = useRef(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [pickerState, setPickerState] = useState<PickerState | null>(null)
  const [documents, setDocuments] = useState<Document[]>([])

  const documentsPathQrCode = Buffer.from(`/application/${friendlyId}/documents`).toString("base64")

  useEffect(() => {
    if (!application.applicants) {
      return
    }
    setDocuments(application.metadata?.documents ?? [])
    setIsLoading(false)
  }, [application])

  useEffect(() => {
    Smartlook((smartLook) => {
      smartLook.track("upload-docs", { friendlyId })
    })
  }, [friendlyId])

  const accordionData = (applicant: Applicant): AccordionDataSection[] => {
    const applicantNumber = applicant.firstApplicant ? 1 : 2
    const docs = sortByType(documents.filter(({ applicantId }) => applicantId === applicant.id))
    return [
      {
        name: "Proof of ID",
        id: "proof-of-id",
        trackingLabelPrefix: `Applicant${applicantNumber}`,
        statusComponent: <DocumentStatus documents={docs.ID} />,
        page: (
          <AccordionContent>
            <AccordionContentInfo>
              <h4>Provide either your Passport or Driving Licence</h4>
              <ul>
                <MojoListItem showTick={true}>
                  <>
                    Must be a current, <strong>valid</strong> document
                  </>
                </MojoListItem>
                <MojoListItem showTick={true}>
                  <>
                    Must show your <strong>name</strong> <strong>and date of birth</strong>
                  </>
                </MojoListItem>
                <MojoListItem showTick={true}>
                  <>
                    A clear, high quality <strong>colour scan</strong> or <strong>photo*</strong>
                  </>
                </MojoListItem>
                <MojoListItem showTick={true}>All four corners visible</MojoListItem>
              </ul>
              <p>
                *In some circumstances we may need a physical copy, but a clear, high quality photo
                or scan will usually do.
              </p>
            </AccordionContentInfo>
            <UploadBoxContainer>
              <UploadBox
                buttonText="Choose files"
                applicantNumber={applicantNumber}
                docType={"ID"}
                onClick={() => {
                  setPickerState({
                    applicant,
                    documentType: "ID",
                  })
                }}
                uploadedItems={docs.ID.map(({ originalFilename }) => ({ originalFilename }))}
              />
            </UploadBoxContainer>
          </AccordionContent>
        ),
      },
      {
        name: "Proof of income",
        id: "proof-of-income",
        trackingLabelPrefix: `Applicant${applicantNumber}`,
        page: (
          <AccordionContent>
            <AccordionContentInfo>
              <h4>Provide the last 3 months’ payslips</h4>
              <ul>
                <MojoListItem showTick={true}>
                  <>
                    Must show your <strong>name</strong>, <strong>current address</strong> and{" "}
                    <strong>income</strong>
                  </>
                </MojoListItem>
                <MojoListItem showTick={true}>
                  <>
                    Upload a <strong>PDF document</strong> from your employer or HMRC, preferably an
                    original document or a clear photo/scan of a paper copy with all four corners
                    visible
                  </>
                </MojoListItem>
                <MojoListItem showTick={true}>
                  <>
                    If <strong>self-employed</strong>, provide instead{" "}
                    <strong>2 years’ SA302s</strong> and either the corresponding{" "}
                    <strong>self-employed tax year overviews or company accounts</strong>
                  </>
                </MojoListItem>
              </ul>
            </AccordionContentInfo>
            <UploadBoxContainer>
              <UploadBox
                buttonText="Choose files"
                applicantNumber={applicantNumber}
                docType={"Income"}
                onClick={() => {
                  setPickerState({
                    applicant,
                    documentType: "Income",
                  })
                }}
                uploadedItems={docs.Income.map(({ originalFilename }) => ({
                  originalFilename,
                }))}
              />
            </UploadBoxContainer>
          </AccordionContent>
        ),
        statusComponent: <DocumentStatus documents={docs.Income} />,
      },
      {
        name: "Bank statements",
        id: "bank-statements",
        trackingLabelPrefix: `Applicant${applicantNumber}`,
        page: (
          <AccordionContent>
            <AccordionContentInfo>
              <h4>Provide the last 3 months’ bank statements</h4>
              <ul>
                <MojoListItem showTick={true}>
                  <>
                    Must show your <strong>name, current address, income being credited</strong>,
                    and <strong>regular outgoings</strong>
                  </>
                </MojoListItem>
                <MojoListItem showTick={true}>
                  <>
                    Upload a <strong>PDF document</strong> from your employer or HMRC, preferably an
                    original document or a clear photo/scan of a paper copy with all four corners
                    visible
                  </>
                </MojoListItem>
                <MojoListItem showTick={true}>
                  <>
                    <strong>Every page</strong> of all statements included
                  </>
                </MojoListItem>
              </ul>
            </AccordionContentInfo>
            <UploadBoxContainer>
              <UploadBox
                buttonText="Choose files"
                applicantNumber={applicantNumber}
                docType={"BankStatements"}
                onClick={() => {
                  setPickerState({
                    applicant,
                    documentType: "BankStatements",
                  })
                }}
                uploadedItems={docs.BankStatements.map(({ originalFilename }) => ({
                  originalFilename,
                }))}
              />
            </UploadBoxContainer>
          </AccordionContent>
        ),
        statusComponent: <DocumentStatus documents={docs.BankStatements} />,
      },
      {
        name: "Other documents",
        id: "other-documents",
        trackingLabelPrefix: `Applicant${applicantNumber}`,
        page: (
          <AccordionContent>
            <AccordionContentInfo>
              <OtherDocuments>
                <p>
                  Once we start putting together your mortgage application, we may need more from
                  you.
                </p>
                <p>Additional documents could include:</p>
                <ul>
                  <li>Proof of deposit for your purchase</li>
                  <li>
                    Evidence of other sources of income or supplements to your basic income like
                    bonuses or regular overtime
                  </li>
                  <li>Proof of right to reside in the UK</li>
                  <li>
                    Additional proof of address such as a council tax statement or a utility bill
                  </li>
                </ul>
                <p>
                  Your mortgage expert or case manager will keep you informed of what else is
                  needed.
                </p>
              </OtherDocuments>
            </AccordionContentInfo>
            <UploadBoxContainer>
              <UploadBox
                buttonText="Choose files"
                applicantNumber={applicantNumber}
                docType={"Other"}
                onClick={() => {
                  setPickerState({
                    applicant,
                    documentType: "Other",
                  })
                }}
                uploadedItems={docs.Other.map(({ originalFilename }) => ({
                  originalFilename,
                }))}
              />
            </UploadBoxContainer>
          </AccordionContent>
        ),
        statusComponent: <DocumentStatus documents={docs.Other} />,
      },
    ]
  }

  return (
    <Root>
      <HeaderContent>
        <HeaderContentLHS>
          <Header>Your documents</Header>
          <SubHeader>
            Adding documents is quick and easy and helps to make sure everything goes as smoothly as
            possible with your mortgage recommendation and application.
            <br />
            <br /> Need help? Check out our{" "}
            <button onClick={() => faqRef.current.scrollIntoView()}>FAQs.</button>
          </SubHeader>
          <ReassuranceBanner>
            <div>
              <ShieldContainer>
                <Shield />
              </ShieldContainer>
              <ReassuranceBannerTextContainer>
                <p>
                  All your documents are <strong>securely stored</strong>
                </p>
              </ReassuranceBannerTextContainer>
            </div>
          </ReassuranceBanner>
        </HeaderContentLHS>
        <HeaderContentRHS>
          {!qrIsLoading && qrData && documentsPathQrCode && (
            <QRCodeContainer>
              <img src={qrData} height={150} width={150} />
              <p>Scan the QR to upload files & photos from your phone</p>
            </QRCodeContainer>
          )}
        </HeaderContentRHS>
      </HeaderContent>

      {isLoading ? (
        <LoaderContainer>
          <Loader size={150} theme={LoaderTheme.Outline} />
        </LoaderContainer>
      ) : (
        <>
          {application.applicants?.map((applicant, idx) => (
            <AccordionWrapper key={idx}>
              <h3>{applicant.forename}’s documents</h3>
              <Accordion data={accordionData(applicant)} />
            </AccordionWrapper>
          ))}
          <FaqContainer ref={faqRef}>
            <p>
              <span>
                All documents provided to us will be thoroughly assessed for authenticity to ensure
                compliance and security, any suspicion of fraudulent activity will be reported.
              </span>
            </p>
            <h3>Document FAQs</h3>
            <Accordion data={Faqs} />
          </FaqContainer>
        </>
      )}

      {pickerState && (
        <FilestackUploadPicker
          close={() => {
            setPickerState(null)
          }}
          container={FILESTACK_CONTAINER}
          uploadedFilePath={`/${pickerState.applicant.id}-${friendlyId}/`}
          customOptions={{
            accept:
              pickerState.documentType === "BankStatements" || pickerState.documentType === "Income"
                ? ["application/pdf"]
                : ["application/pdf", "image/*"],
            onClose: () => {
              setPickerState(null)
            },
            onFileSelected(file) {
              const { forename, surname } = pickerState.applicant
              return {
                ...file,
                name: `${forename}${surname}-${pickerState.documentType}.${file.originalFile.name}`,
              }
            },
            disableStorageKey: true,
          }}
          onSuccessFunction={async (filesUploaded) => {
            filesUploaded = filesUploaded.map((file) => {
              const { forename, surname } = pickerState.applicant
              const directoryName = file.key.split("/")[0]
              const fileName = `${forename}${surname}-${pickerState.documentType}.${
                file.key.split("/")[1]
              }`

              return {
                ...file,
                key: `${directoryName}/${fileName}`,
              }
            })
            try {
              // It's possible for onSuccessFunction to be called with an empty
              // array. Not sure how, it seems to be an error in the FileStack
              // picker. But if we don't have filesUploaded, do nothing.
              if (filesUploaded.length < 1) return

              const nextDocuments = await UploadDocumentMetaData(
                filesUploaded,
                application.applicationId,
                pickerState.applicant.id,
                pickerState.documentType
              )
              LogGaEvent({
                action: EventAction.documentUploaded,
                event_category: EventCategory.functionalInteraction,
                event_label: `Applicant${pickerState.applicant.firstApplicant ? 1 : 2} - ${
                  pickerState.documentType
                }`,
              })
              setDocuments(nextDocuments)
            } catch (ex) {
              LogError(ex)
            }
          }}
        />
      )}
    </Root>
  )
}
