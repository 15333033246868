import React from "react"
import * as Sentry from "@sentry/browser"
import { Redirect } from "react-router-dom"

type SentryErrorerProps = {
  path: string
  redirect?: string
  doRedirect?: () => void
}

/**
 * This component exists to replace routes that we think are unused.
 * To ensure nobody is actually visiting these, when rendered
 * this component will send an error to Sentry.
 */
export const SentryErrorer = ({
  path,
  redirect = "/dashboard",
  doRedirect,
}: SentryErrorerProps) => {
  React.useEffect(() => {
    console.log(window)
    Sentry.captureException(new Error(`User tried accessing ${path}`))

    if (doRedirect) {
      doRedirect()
    }
  }, [])

  if (doRedirect) {
    return <></>
  }

  return <Redirect to={redirect} />
}
