import dayjs from "dayjs"
import { ConveyancingBody, Quote } from "../types"

export interface FilteredQuotes {
  quotes: Quote[]
  expired: boolean
  isIndicative: boolean
  expirationDate: Date
  aditionalQuotes: Quote[]
}

const EMPTY: FilteredQuotes = {
  quotes: [],
  expired: false,
  isIndicative: false,
  expirationDate: new Date(),
  aditionalQuotes: [],
}

export const FilteredQuotes = { EMPTY }

export const filterQuotes = (conveyancingBody: ConveyancingBody): FilteredQuotes => {
  const quoteList = conveyancingBody[0]
  console.log("quoteList", quoteList)

  const preferredQuotes = quoteList.quotes.filter((q) => q.isPreferred)

  if (preferredQuotes.length > 0) {
    return {
      quotes: preferredQuotes,
      expired: quoteList.expired,
      isIndicative: quoteList.isIndicative,
      expirationDate: dayjs(quoteList.expirationDate).toDate(),
      aditionalQuotes: quoteList.quotes.filter((q) => !q.isPreferred),
    }
  } else {
    return {
      quotes: quoteList.quotes.filter((q) => q.isShortlisted),
      expired: quoteList.expired,
      isIndicative: quoteList.isIndicative,
      expirationDate: dayjs(quoteList.expirationDate).toDate(),
      aditionalQuotes: quoteList.quotes.filter((q) => !q.isShortlisted),
    }
  }
}
