import useRateCheck from "hooks/useRateCheck"
import { LogGaEvent } from "lib/GoogleAnalytics"
import { EventAction, EventCategory } from "models/GoogleAnalytics"
import { StatusPageNavSelected } from "pages/Application/Eligibility/Status/index"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

const List = styled.ul`
  padding: 0;
  list-style: none;
`

const ListItem = styled.li<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? "#F5F7FB" : "white")};
  margin: 0 -32px;

  cursor: pointer;

  :hover {
    background: ${({ isActive }) => (!isActive ? "rgba(245, 247, 251, 0.5)" : "#F5F7FB")};
  }
`

const Border = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9edf5;
  margin: 0 32px;
`

const BulletPoint = styled.div<{ active: boolean }>`
  border-radius: 50%;
  height: 1.5em;
  width: 1.5em;
  border: 8px solid ${({ active }) => (active ? "#073ed9" : "#c1c5d1")};
  margin-right: 18px;
`

interface Props {
  friendlyId: string
  selected: StatusPageNavSelected
  setIsOpen: (isOpen: boolean) => void
}

export const SidebarNav = ({ friendlyId, selected, setIsOpen }: Props) => {
  const history = useHistory()
  const inRateCheckExperiment = useRateCheck(friendlyId)

  // Occasionally the experiment will not be loaded in time
  // and the RCP doesn't show in the sidebar at all
  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    if (inRateCheckExperiment !== undefined) {
      setIsLoading(false)
    }
  }, [inRateCheckExperiment])

  return (
    <List>
      <ListItem
        isActive={selected === StatusPageNavSelected.Status}
        onClick={() => {
          LogGaEvent({
            action: EventAction.navigation,
            event_category: EventCategory.functionalInteraction,
            event_label: "Status",
          })
          setIsOpen(false)
          history.push(`/application/${friendlyId}/status`)
        }}
      >
        <Border>
          <BulletPoint active={selected === StatusPageNavSelected.Status} />
          <p>Status</p>
        </Border>
      </ListItem>
      <ListItem
        isActive={selected === StatusPageNavSelected.Recommendation}
        onClick={() => {
          LogGaEvent({
            action: EventAction.navigation,
            event_category: EventCategory.functionalInteraction,
            event_label: "Recommendation",
          })
          setIsOpen(false)
          history.push(`/application/${friendlyId}/recommendation`)
        }}
      >
        <Border>
          <BulletPoint active={selected === StatusPageNavSelected.Recommendation} />
          <p>Recommendation</p>
        </Border>
      </ListItem>
      {inRateCheckExperiment && (
        <ListItem
          isActive={selected === StatusPageNavSelected.RateChecker}
          onClick={() => {
            LogGaEvent({
              action: EventAction.navigation,
              event_category: EventCategory.functionalInteraction,
              event_label: "Rate Checker",
            })
            setIsOpen(false)
            history.push(`/application/${friendlyId}/rate-check-promise`)
          }}
        >
          <Border>
            <BulletPoint active={selected === StatusPageNavSelected.RateChecker} />
            <p>Rate Check Promise</p>
          </Border>
        </ListItem>
      )}
      <ListItem
        isActive={selected === StatusPageNavSelected.Conveyancing}
        onClick={() => {
          LogGaEvent({
            action: EventAction.navigation,
            event_category: EventCategory.functionalInteraction,
            event_label: "Conveyancing",
          })
          setIsOpen(false)
          history.push(`/application/${friendlyId}/conveyancing`)
        }}
      >
        <Border>
          <BulletPoint active={selected === StatusPageNavSelected.Conveyancing} />
          <p>Conveyancing</p>
        </Border>
      </ListItem>
      <ListItem
        isActive={selected === StatusPageNavSelected.Documents}
        onClick={() => {
          LogGaEvent({
            action: EventAction.navigation,
            event_category: EventCategory.functionalInteraction,
            event_label: "Documents",
          })
          setIsOpen(false)
          history.push(`/application/${friendlyId}/documents`)
        }}
      >
        <Border>
          <BulletPoint active={selected === StatusPageNavSelected.Documents} />
          <p>Documents</p>
        </Border>
      </ListItem>
    </List>
  )
}
